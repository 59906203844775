var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.inStoreForm,
        "label-width": "96px",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
          attrs: { id: "wrap" },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isImportOrderBtn: _vm.showImportBill,
              disabled: _vm.disabled,
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
              id: "topOperatingButton",
            },
            on: {
              submitForm: _vm.submitForm,
              addBill: _vm.addBill,
              auditBill: _vm.auditBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
              getImportOrder: _vm.getImportOrder,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                id: "basicInformation",
                billStatus: _vm.inStoreForm.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: true,
                            placeholder: "单据编号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.inStoreForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.inStoreForm, "billNo", $$v)
                            },
                            expression: "inStoreForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            size: "mini",
                          },
                          model: {
                            value: _vm.inStoreForm.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.inStoreForm, "billDate", $$v)
                            },
                            expression: "inStoreForm.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手工单据号", prop: "handBillNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "手工单据号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.inStoreForm.handBillNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.inStoreForm, "handBillNo", $$v)
                            },
                            expression: "inStoreForm.handBillNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                              option: {
                                option: {
                                  disabled: _vm.disabled || _vm.isImportSource,
                                },
                              },
                            }).option,
                          },
                          on: { selectChange: _vm.changeProduceGroupChange },
                          model: {
                            value: _vm.inStoreForm.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.inStoreForm, "produceDeptId", $$v)
                            },
                            expression: "inStoreForm.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "部门", prop: "deptId" } },
                      [
                        _c("SelectLocal", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listSimpleQuery",
                              option: {
                                option: {
                                  disabled: _vm.disabled,
                                  showItem: [
                                    {
                                      deptId: _vm.inStoreForm.deptId,
                                      deptName: _vm.inStoreForm.deptName,
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.inStoreForm.deptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.inStoreForm, "deptId", $$v)
                            },
                            expression: "inStoreForm.deptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 5 },
                            size: "mini",
                          },
                          model: {
                            value: _vm.inStoreForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.inStoreForm, "billRemark", $$v)
                            },
                            expression: "inStoreForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "rightCardTitle" }, [
                _c(
                  "div",
                  { staticClass: "marR10 x-f" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "marL10",
                        attrs: {
                          disabled:
                            _vm.disabled || !_vm.inStoreForm.produceDeptId,
                          type: "primary",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getDistribution(1)
                          },
                        },
                      },
                      [_vm._v("批量修改仓库")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "vxe-table",
                      {
                        ref: "multipleTable",
                        attrs: {
                          border: "",
                          "show-footer": "",
                          "show-overflow": "",
                          "show-header-overflow": "",
                          data: _vm.inStoreForm.details,
                          loading: _vm.loadingTable,
                          "footer-method": _vm.footerMethod,
                          "max-height": "600px",
                          height: "600px",
                          "checkbox-config": {
                            trigger: "row",
                            checkMethod: function () {
                              return !_vm.disabled
                            },
                          },
                          "row-config": { isHover: true, height: 60 },
                          "sort-config": { trigger: "cell" },
                          "scroll-y": { enabled: true },
                        },
                        on: {
                          "checkbox-change": _vm.handleSelectionChange,
                          "checkbox-all": _vm.handleSelectionChange,
                        },
                      },
                      [
                        _c("vxe-column", {
                          attrs: {
                            type: "checkbox",
                            width: "60",
                            align: "center",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            align: "center",
                            title: _vm.tableCellLabel,
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.cellMouseEnter(scope.row)
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.cellMouseLeave(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.row(
                                              "push",
                                              scope.rowIndex
                                            )
                                          },
                                        },
                                      }),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.row(
                                              "del",
                                              scope.rowIndex
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !scope.row.hoverRow,
                                              expression: "!scope.row.hoverRow",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.rowIndex + 1) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "商品编码",
                            "min-width": "180",
                            align: "center",
                            field: "goodsNo",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "details." +
                                          scope.rowIndex +
                                          ".goodsNo",
                                        rules: _vm.rules["details.goodsNo"],
                                      },
                                    },
                                    [
                                      _c("SelectRemote", {
                                        ref:
                                          "goodsNo" +
                                          "_" +
                                          scope.rowIndex +
                                          "_1",
                                        attrs: {
                                          disabled: _vm.disabled,
                                          option: _vm.optionGoods(scope.row),
                                        },
                                        on: {
                                          handleSelectKeydown: function (
                                            $event
                                          ) {
                                            return _vm.handleTableKeydown(
                                              $event,
                                              scope.row,
                                              "goodsNo",
                                              scope.rowIndex,
                                              1
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.goodsNo,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "goodsNo", $$v)
                                          },
                                          expression: "scope.row.goodsNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "商品名称",
                            align: "center",
                            field: "goodsName",
                            "min-width": "130",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "商品条码",
                            align: "center",
                            field: "barcode",
                            "min-width": "170",
                          },
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "规格",
                              align: "center",
                              field: "goodsSpec",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.rowIndex +
                                            ".goodsSpec",
                                          rules: _vm.rules["details.goodsSpec"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            ref:
                                              "goodsSpec" +
                                              "_" +
                                              scope.rowIndex +
                                              "_4",
                                            attrs: {
                                              disabled: _vm.disabled,
                                              size: "mini",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeUnits(
                                                  "goodsSpec",
                                                  scope.row,
                                                  scope.row.goodsSpec
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keydown: function ($event) {
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "goodsSpec",
                                                  scope.rowIndex,
                                                  4
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.goodsSpec,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "goodsSpec",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.goodsSpec",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.goodsSpec,
                                                attrs: {
                                                  label: item.goodsSpec,
                                                  value: item.goodsSpec,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("规格")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "生产日期",
                            align: "center",
                            field: "produceDate",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-date-picker", {
                                    ref:
                                      "produceDate" +
                                      "_" +
                                      scope.rowIndex +
                                      "_5",
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      size: "mini",
                                      type: "date",
                                      placeholder: "单据日期",
                                      disabled: _vm.disabled,
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        return _vm.handleTableKeydown(
                                          $event,
                                          scope.row,
                                          "produceDate",
                                          scope.rowIndex,
                                          5
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.produceDate,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "produceDate", $$v)
                                      },
                                      expression: "scope.row.produceDate",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "单位",
                              align: "center",
                              field: "unitId",
                              "min-width": "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.rowIndex +
                                            ".unitId",
                                          rules: _vm.rules["details.unitId"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            ref:
                                              "unitId" +
                                              "_" +
                                              scope.rowIndex +
                                              "_6",
                                            attrs: {
                                              size: "mini",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeUnits(
                                                  "unitId",
                                                  scope.row,
                                                  scope.row.unitId
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keydown: function ($event) {
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitId",
                                                  scope.rowIndex,
                                                  6
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.unitId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitId",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitId",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.unitId,
                                                attrs: {
                                                  label: item.unitName,
                                                  value: item.unitId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("单位")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "源单单号",
                            align: "center",
                            field: "sourceBillNo",
                            "min-width": "160",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "生产批号",
                            align: "center",
                            "min-width": "180",
                            field: "batchNo",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    ref:
                                      "batchNo" + "_" + scope.rowIndex + "_8",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      size: "mini",
                                      maxlength: "20",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                      keydown: function ($event) {
                                        return _vm.handleTableKeydown(
                                          $event,
                                          scope.row,
                                          "batchNo",
                                          scope.rowIndex,
                                          8
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.batchNo,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "batchNo", $$v)
                                      },
                                      expression: "scope.row.batchNo",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "生产计划数",
                            align: "center",
                            "min-width": "130",
                            field: "importUnitQty",
                          },
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "入库仓库",
                              align: "center",
                              "min-width": "150",
                              field: "inStoreId",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.rowIndex +
                                            ".inStoreId",
                                          rules: _vm.rules["details.inStoreId"],
                                        },
                                      },
                                      [
                                        _c("SelectRemote", {
                                          ref:
                                            "inStoreId" +
                                            "_" +
                                            scope.rowIndex +
                                            "_10",
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            disabled:
                                              _vm.disabled ||
                                              !_vm.inStoreForm.produceDeptId,
                                            option: _vm.$select({
                                              key: "listProduceStore",
                                              option: {
                                                option: {
                                                  remoteBody: {
                                                    produceDeptId:
                                                      _vm.inStoreForm
                                                        .produceDeptId,
                                                  },
                                                  disabled: _vm.disabled,
                                                  showItem: [
                                                    {
                                                      storeId:
                                                        scope.row.inStoreId,
                                                      produceStoreName:
                                                        scope.row.inStoreName,
                                                    },
                                                  ],
                                                },
                                              },
                                            }).option,
                                          },
                                          on: {
                                            handleSelectKeydown: function (
                                              $event
                                            ) {
                                              return _vm.handleTableKeydown(
                                                $event,
                                                scope.row,
                                                "outStoreId",
                                                scope.rowIndex,
                                                10
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.inStoreId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "inStoreId",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.inStoreId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("入库仓库")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "已入库数",
                            align: "center",
                            "min-width": "130",
                            field: "unitAlreadyInQty",
                          },
                        }),
                        _c(
                          "vxe-column",
                          {
                            attrs: {
                              title: "入库数量",
                              align: "center",
                              "min-width": "130",
                              field: "unitQty",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.rowIndex +
                                            ".unitQty",
                                          rules: _vm.rules["details.unitQty"],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          ref:
                                            "unitQty" +
                                            "_" +
                                            scope.rowIndex +
                                            "_12",
                                          attrs: {
                                            disabled: _vm.disabled,
                                            type: "number",
                                            size: "mini",
                                            maxlength: "7",
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(1, v)
                                            },
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.limitInputlength(
                                                scope.row.unitQty,
                                                scope.row,
                                                "unitQty",
                                                true
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            mousewheel: function ($event) {
                                              $event.preventDefault()
                                            },
                                            DOMMouseScroll: function ($event) {
                                              $event.preventDefault()
                                            },
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                            keyup: function ($event) {
                                              return _vm.onkeyupUnitQty(
                                                scope.row
                                              )
                                            },
                                            keydown: [
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "up",
                                                    38,
                                                    $event.key,
                                                    ["Up", "ArrowUp"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  12
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "down",
                                                    40,
                                                    $event.key,
                                                    ["Down", "ArrowDown"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  12
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "left",
                                                    37,
                                                    $event.key,
                                                    ["Left", "ArrowLeft"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 0
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  12
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "right",
                                                    39,
                                                    $event.key,
                                                    ["Right", "ArrowRight"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 2
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  12
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.rowIndex,
                                                  12
                                                )
                                              },
                                            ],
                                          },
                                          model: {
                                            value: scope.row.unitQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "unitQty",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.unitQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("入库数量")]),
                            ]),
                          ],
                          2
                        ),
                        _c("vxe-column", {
                          attrs: {
                            title: "合格数量",
                            align: "center",
                            "min-width": "130",
                            field: "unitEligibleQty",
                          },
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "生产次品",
                            align: "center",
                            "min-width": "130",
                            field: "unitProduceInferiorQty",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    ref:
                                      "unitProduceInferiorQty" +
                                      "_" +
                                      scope.rowIndex +
                                      "_14",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "number",
                                      size: "mini",
                                      maxlength: "7",
                                      sunyunFormat: function (v) {
                                        return _vm.$syInput(1, v)
                                      },
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.limitInputlength(
                                          scope.row.unitProduceInferiorQty,
                                          scope.row,
                                          "unitProduceInferiorQty",
                                          true
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      mousewheel: function ($event) {
                                        $event.preventDefault()
                                      },
                                      DOMMouseScroll: function ($event) {
                                        $event.preventDefault()
                                      },
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                      keyup: function ($event) {
                                        return _vm.onkeyupUnitQty(scope.row)
                                      },
                                      keydown: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "up",
                                              38,
                                              $event.key,
                                              ["Up", "ArrowUp"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitProduceInferiorQty",
                                            scope.rowIndex,
                                            14
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "down",
                                              40,
                                              $event.key,
                                              ["Down", "ArrowDown"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitProduceInferiorQty",
                                            scope.rowIndex,
                                            14
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "left",
                                              37,
                                              $event.key,
                                              ["Left", "ArrowLeft"]
                                            )
                                          ) {
                                            return null
                                          }
                                          if (
                                            "button" in $event &&
                                            $event.button !== 0
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitProduceInferiorQty",
                                            scope.rowIndex,
                                            14
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "right",
                                              39,
                                              $event.key,
                                              ["Right", "ArrowRight"]
                                            )
                                          ) {
                                            return null
                                          }
                                          if (
                                            "button" in $event &&
                                            $event.button !== 2
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitProduceInferiorQty",
                                            scope.rowIndex,
                                            14
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitProduceInferiorQty",
                                            scope.rowIndex,
                                            14
                                          )
                                        },
                                      ],
                                    },
                                    model: {
                                      value: scope.row.unitProduceInferiorQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "unitProduceInferiorQty",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row.unitProduceInferiorQty",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "包装次品",
                            align: "center",
                            "min-width": "130",
                            field: "unitPackInferiorQty",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    ref:
                                      "unitPackInferiorQty" +
                                      "_" +
                                      scope.rowIndex +
                                      "_15",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "number",
                                      size: "mini",
                                      maxlength: "7",
                                      sunyunFormat: function (v) {
                                        return _vm.$syInput(1, v)
                                      },
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.limitInputlength(
                                          scope.row.unitPackInferiorQty,
                                          scope.row,
                                          "unitPackInferiorQty",
                                          true
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      mousewheel: function ($event) {
                                        $event.preventDefault()
                                      },
                                      DOMMouseScroll: function ($event) {
                                        $event.preventDefault()
                                      },
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                      keyup: function ($event) {
                                        return _vm.onkeyupUnitQty(scope.row)
                                      },
                                      keydown: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "up",
                                              38,
                                              $event.key,
                                              ["Up", "ArrowUp"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitPackInferiorQty",
                                            scope.rowIndex,
                                            15
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "down",
                                              40,
                                              $event.key,
                                              ["Down", "ArrowDown"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitPackInferiorQty",
                                            scope.rowIndex,
                                            15
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "left",
                                              37,
                                              $event.key,
                                              ["Left", "ArrowLeft"]
                                            )
                                          ) {
                                            return null
                                          }
                                          if (
                                            "button" in $event &&
                                            $event.button !== 0
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitPackInferiorQty",
                                            scope.rowIndex,
                                            15
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "right",
                                              39,
                                              $event.key,
                                              ["Right", "ArrowRight"]
                                            )
                                          ) {
                                            return null
                                          }
                                          if (
                                            "button" in $event &&
                                            $event.button !== 2
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitPackInferiorQty",
                                            scope.rowIndex,
                                            15
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          return _vm.handleTableKeydown(
                                            $event,
                                            scope.row,
                                            "unitPackInferiorQty",
                                            scope.rowIndex,
                                            15
                                          )
                                        },
                                      ],
                                    },
                                    model: {
                                      value: scope.row.unitPackInferiorQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "unitPackInferiorQty",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row.unitPackInferiorQty",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("vxe-column", {
                          attrs: {
                            title: "备注",
                            align: "center",
                            field: "remark",
                            "min-width": "130",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    ref:
                                      "remark" + "_" + scope.rowIndex + "_16",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      size: "mini",
                                      maxlength: "80",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                      keydown: function ($event) {
                                        return _vm.handleTableKeydown(
                                          $event,
                                          scope.row,
                                          "remark",
                                          scope.rowIndex,
                                          16
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }